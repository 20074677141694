import { useUser } from '~~/store/user';

export default defineNuxtRouteMiddleware(async (to) => {
  if (process.client) {
    const { $pinia } = useNuxtApp();
    const user = useUser($pinia);
    await user.isModuleReady();

    const destinationPageName = String(to.name || '');
    const unloggedPages = [
      'index',
      'find-a-role',
      'login',
      'register',
      'forgot-password',
      'pricing',
      'jobs',
      'jobs-jobTitle--jobUniqueId',
      'jobs-jobTitle--jobUniqueId-index',
    ];

    if (
      !user.isLogged &&
      !unloggedPages.includes(destinationPageName)
    ) {
      return { name: 'login' };
    }

    return { name: 'dashboard' };
  }
});
